import { FC } from "react";

interface OptiesProps {
  isActive?: boolean;
}

const Opties: FC<OptiesProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.155"
      height="30.456"
      viewBox="0 0 29.155 30.456"
    >
      <path
        id="cog-solid"
        d="M47.427,26.987l-2.616-1.511a11.827,11.827,0,0,0,0-4.31l2.616-1.511a.742.742,0,0,0,.338-.86,15.293,15.293,0,0,0-3.359-5.809.739.739,0,0,0-.909-.141l-2.616,1.511A11.588,11.588,0,0,0,37.148,12.2V9.186a.736.736,0,0,0-.577-.718,15.436,15.436,0,0,0-6.705,0,.736.736,0,0,0-.577.718v3.021a11.954,11.954,0,0,0-3.733,2.155l-2.61-1.511a.73.73,0,0,0-.909.141A15.2,15.2,0,0,0,18.678,18.8a.734.734,0,0,0,.338.86l2.616,1.511a11.828,11.828,0,0,0,0,4.31l-2.616,1.511a.742.742,0,0,0-.338.86,15.293,15.293,0,0,0,3.359,5.809.739.739,0,0,0,.909.141l2.616-1.511a11.588,11.588,0,0,0,3.733,2.155v3.021a.736.736,0,0,0,.577.718,15.436,15.436,0,0,0,6.705,0,.736.736,0,0,0,.577-.718V34.447a11.954,11.954,0,0,0,3.733-2.155L43.5,33.8a.73.73,0,0,0,.909-.141,15.2,15.2,0,0,0,3.359-5.809A.758.758,0,0,0,47.427,26.987ZM33.218,28.233a4.912,4.912,0,1,1,4.912-4.912A4.919,4.919,0,0,1,33.218,28.233Z"
        transform="translate(-18.644 -8.099)"
        fill="#fff"
        opacity={isActive ? "1" : "0.5"}
      />
    </svg>
  );
};

export default Opties;
