import { FC, useEffect, useState } from "react";
import { RoutesPaths } from "../../RoutePaths";
import Archief from "./assets/Archief";
import Logo from "./assets/Logo";
import Opites from "./assets/Opties";
import Planning from "./assets/Planning";
import Productie from "./assets/Productie";
import Status from "./assets/Status";
import { NavigationLink, SideBarContainer, StyledSpan } from "./styled";
import { SideBarProps } from "./types";

const SideBar: FC<SideBarProps> = () => {
  const [activeTab, setActiveTab] = useState<string>(window.location.pathname);
  useEffect(() => {
    if (activeTab === "/") {
      setActiveTab(RoutesPaths.PRODUCTIE);
    }
  }, [activeTab]);
  const handleChangePath = (path: string) => {
    setActiveTab(path);
  };

  return (
    <SideBarContainer>
      <Logo />
      <NavigationLink
        activetab={(activeTab === RoutesPaths.PRODUCTIE).toString()}
        onClick={() => handleChangePath(RoutesPaths.PRODUCTIE)}
        to={RoutesPaths.PRODUCTIE}
      >
        <Productie isActive={activeTab === RoutesPaths.PRODUCTIE} />
        <StyledSpan white={activeTab === RoutesPaths.PRODUCTIE}>
          productie
        </StyledSpan>
      </NavigationLink>
      <NavigationLink
        activetab={(activeTab === RoutesPaths.STATUS).toString()}
        onClick={() => handleChangePath(RoutesPaths.STATUS)}
        to={RoutesPaths.STATUS}
      >
        <Status isActive={activeTab === RoutesPaths.STATUS} />
        <StyledSpan white={activeTab === RoutesPaths.STATUS}>status</StyledSpan>
      </NavigationLink>
      <NavigationLink
        activetab={(activeTab === RoutesPaths.PLANNING).toString()}
        onClick={() => handleChangePath(RoutesPaths.PLANNING)}
        to={RoutesPaths.PLANNING}
      >
        <Planning isActive={activeTab === RoutesPaths.PLANNING} />
        <StyledSpan white={activeTab === RoutesPaths.PLANNING}>
          planning
        </StyledSpan>
      </NavigationLink>
      <NavigationLink
        activetab={(activeTab === RoutesPaths.ARCHIEF).toString()}
        onClick={() => handleChangePath(RoutesPaths.ARCHIEF)}
        to={RoutesPaths.ARCHIEF}
      >
        <Archief isActive={activeTab === RoutesPaths.ARCHIEF} />
        <StyledSpan white={activeTab === RoutesPaths.ARCHIEF}>
          archief
        </StyledSpan>
      </NavigationLink>
      <NavigationLink
        activetab={(activeTab === RoutesPaths.OPTIES).toString()}
        onClick={() => handleChangePath(RoutesPaths.OPTIES)}
        to={RoutesPaths.OPTIES}
      >
        <Opites isActive={activeTab === RoutesPaths.OPTIES} />
        <StyledSpan white={activeTab === RoutesPaths.OPTIES}>opties</StyledSpan>
      </NavigationLink>
    </SideBarContainer>
  );
};

export default SideBar;
