import React, { FC, Suspense } from "react";
import Loader from "../common/components/Loader";
const StatusContainer = React.lazy(
  () => import("../containers/StatusContainer")
);

const StatusPage: FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <StatusContainer />
      </Suspense>
    </>
  );
};

export default StatusPage;
