import { FC } from "react";
import { Navigate } from "react-router-dom";
import { RoutesProps } from "./model";
import { useLogin } from "../../common/utils/hooks/useLogin";

const PrivateRoute: FC<RoutesProps> = ({ children }): JSX.Element => {
  const isLogin = useLogin();

  return isLogin ? <>{children}</> : <Navigate to="/" />;
};

export default PrivateRoute;
