import { Loader } from "@mantine/core";
import React from "react";
import { LoaderContainer } from "./styled";

const LoaderComponent = () => {
  return (
    <LoaderContainer>
      <Loader color="orange" size="xl" />
    </LoaderContainer>
  );
};

export default LoaderComponent;
