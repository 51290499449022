import React, { FC, Suspense } from "react";
import Loader from "../common/components/Loader";
const OptiesContainer = React.lazy(
  () => import("../containers/OptiesContainer")
);

const OptiesPage: FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <OptiesContainer />
      </Suspense>
    </>
  );
};

export default OptiesPage;
