import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { OptionsStateType } from "./options.types";
import {
  getOrderItemsThunk,
  getSettingsThunk,
  runAlgoThunk,
  updateBatchesSettingsThunk,
} from "./options.thunks";

const defaultLastSynchronizedDate = new Date(
  new Date().setHours(8, 0)
).toISOString(); // default cron time
const groupedTrelloProductIds =
  process.env.REACT_APP_GROUPED_SKU?.split(",") ?? [];

const initialState: OptionsStateType = {
  orderItems: [],
  isLoading: false,
  settings: {
    lastSynchronizedDate: defaultLastSynchronizedDate,
    batchesPerDay: 7,
    batchSize: 20,
    lastAlgoBatchesPerDay: 7,
    lastAlgoBatchSize: 20,
  },
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderItemsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderItemsThunk.fulfilled, (state, { payload }) => {
      state.orderItems = payload.filter(
        (item) => !groupedTrelloProductIds.includes(item.trelloProductId)
      );
      state.isLoading = false;
    });
    builder.addCase(getSettingsThunk.fulfilled, (state, { payload }) => {
      state.settings = {
        ...payload,
      };
    });
    builder.addCase(runAlgoThunk.fulfilled, (state, { payload: status }) => {
      if (status === 204) {
        // no content
        toast("No new order items found", {
          closeButton: true,
          type: "info",
        });
      }
    });
    builder.addCase(
      updateBatchesSettingsThunk.fulfilled,
      (state, { payload }) => {
        state.settings = {
          ...state.settings,
          ...payload,
        };
      }
    );
  },
});

export default optionsSlice.reducer;
