import Loader from "../common/components/Loader";
import React, { FC, Suspense } from "react";
const PlanningContainer = React.lazy(
  () => import("../containers/PlanningContainer")
);

const PlanningPage: FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <PlanningContainer />
      </Suspense>
    </>
  );
};

export default PlanningPage;
