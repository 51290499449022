import api from "./";

import { IUpdateBatchPayload } from "../fetures/CardProduct/model";
import { ICompleteBatchPayload } from "../store/products/products.types";

export const getBatches = () => {
  return api.get("/batch");
};

export const completeBatch = (
  batchId: string,
  signal: AbortSignal,
  data: Pick<ICompleteBatchPayload, "items" | "nextBatchId">
) => {
  return api.post(`/batch/gerered/${batchId}`, data, { signal });
};

export const updateBatch = (data: IUpdateBatchPayload) => {
  return api.patch(`/batch/change`, data);
};
