import api from "./";
import { IUpdateOrderItem } from "../store/options/options.types";

export const getTotalOrderItems = () => {
  return api.get("/woocommerce-products/total-order-items");
};

export const updateTotalOrderItems = (items: IUpdateOrderItem[]) => {
  return api.patch("/woocommerce-products/total-order-items", { items });
};
