import api from "./";
import { IUpdateBatchesSettings } from "../store/options/options.types";

export const getSettings = () => {
  return api.get("/settings");
};

export const updateBatchesSettings = (settings: IUpdateBatchesSettings) => {
  return api.patch("/settings/batches-settings", settings);
};
