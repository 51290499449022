import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { IBatchIdItem } from "../options/options.types";
import { completeBatchThunk, getBatchesThunk } from "./products.thunks";
import { CardProductState, Decrement, IBatchItem } from "./products.types";

const initialState: CardProductState = {
  currentCard: "",
  copyBatches: [],
  uncompletedBatches: [],
  completedBatches: [],
  nextDayBatch: null,
  maxOrderItemsQuantity: {},
  groupedBatches: [],
  isCompleteInProgress: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(completeBatchThunk.pending, (state) => {
      state.isCompleteInProgress = true;
    });
    builder.addCase(completeBatchThunk.fulfilled, (state) => {
      state.isCompleteInProgress = false;
    });
    builder.addCase(completeBatchThunk.rejected, (state) => {
      state.isCompleteInProgress = false;
    });
    builder.addCase(getBatchesThunk.fulfilled, (state, { payload }) => {
      const currentBatch = payload.uncompleted[0] as IBatchItem;
      state.currentCard = currentBatch?.id;
      state.groupedBatches = payload.uncompleted
        .reverse()
        .map((batch: IBatchItem) => {
          const groupItems: any[] = [];

          const allTrelloProductId: string[] = [];

          for (const item of batch.orderItems) {
            const filtredItems = batch.orderItems.filter(
              (el) =>
                el.trelloProductId === item.trelloProductId && el.ml === item.ml
            );
            const checkTrelloId = allTrelloProductId.find(
              (el) => el === item.trelloProductId
            );

            if (!checkTrelloId) {
              allTrelloProductId.push(item.trelloProductId);
              groupItems.push(
                filtredItems.length > 1
                  ? {
                      id: nanoid(),
                      name: item.name,
                      imageSrc: item.imageSrc,
                      ml: item.ml,
                      matrices: item.matrices,
                      isEdited: item.isEdited,
                      trelloProductId: item.trelloProductId,
                      orderItems: filtredItems,
                      quantity: filtredItems.reduce((acc, curr) => {
                        acc = acc + curr.quantity;
                        return acc;
                      }, 0),
                    }
                  : item
              );
            }
          }

          return {
            ...batch,
            orderItems: groupItems,
          };
        });

      state.completedBatches = payload.completed.reverse();
      state.copyBatches = state.groupedBatches;
      state.nextDayBatch = payload.nextDayBatch;
      state.uncompletedBatches = payload.uncompleted.reverse();
      if (currentBatch) {
        for (const orderItem of state.groupedBatches[
          state.groupedBatches.length - 1
        ].orderItems) {
          if (!!orderItem?.orderItems) {
            const totalOrderItemQuantity = orderItem.orderItems
              .map((orderItem) => {
                return orderItem.batchId.reduce(
                  (acc: number, value: IBatchIdItem) => {
                    if (value.isCompleted) return acc;

                    return (acc += value.quantity);
                  },
                  0
                );
              })
              .reduce((acc, curr) => {
                acc += curr;
                return acc;
              }, 0);
            state.maxOrderItemsQuantity[orderItem.id] = totalOrderItemQuantity;
          } else {
            const totalOrderItemQuantity = orderItem.batchId.reduce(
              (acc: number, value: IBatchIdItem) => {
                if (value.isCompleted) return acc;

                return (acc += value.quantity);
              },
              0
            );

            state.maxOrderItemsQuantity[orderItem.id] = totalOrderItemQuantity;
          }
        }

        for (const orderItem of currentBatch.orderItems) {
          const totalOrderItemQuantity = orderItem.batchId.reduce(
            (acc: number, value: IBatchIdItem) => {
              if (value.isCompleted) return acc;

              return (acc += value.quantity);
            },
            0
          );

          state.maxOrderItemsQuantity[orderItem.id] = totalOrderItemQuantity;
        }
      }

      if (currentBatch) {
        for (const orderItem of currentBatch.orderItems) {
          const totalOrderItemQuantity = orderItem.batchId.reduce(
            (acc: number, value: IBatchIdItem) => {
              if (value.isCompleted) return acc;

              return (acc += value.quantity);
            },
            0
          );

          state.maxOrderItemsQuantity[orderItem.id] = totalOrderItemQuantity;
        }
      }
    });
  },
  reducers: {
    setIsCompleteInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.isCompleteInProgress = payload;
    },
    setCopyBatches: (state) => {
      state.copyBatches = state.groupedBatches;
      // state.copyBatches = state.uncompletedBatches;
    },
    clearCopyBatches: (state) => {
      state.copyBatches = [];
    },
    setPreviousBatches: (state) => {
      state.groupedBatches = state.copyBatches;
      // state.uncompletedBatches = state.copyBatches;
      state.copyBatches = [];
    },
    increment: (
      state,
      { payload: { newQuantity, orderItemId } }: PayloadAction<Decrement>
    ) => {
      const orderItemMaxQuantity = state.maxOrderItemsQuantity[orderItemId];
      const currentBatch = state.groupedBatches.find(
        (item) => item.id === state.currentCard
      ) as IBatchItem;
      // const currentBatch = state.uncompletedBatches.find(
      //   (item) => item.id === state.currentCard
      // ) as IBatchItem;

      if (newQuantity <= orderItemMaxQuantity) {
        const updatedBatch: IBatchItem = {
          ...currentBatch,
          orderItems: currentBatch.orderItems.map((item) => {
            if (item.id === orderItemId) {
              return {
                ...item,
                quantity: newQuantity,
              };
            }

            return item;
          }),
        };

        state.groupedBatches = state.groupedBatches.map((item) => {
          if (item.id === updatedBatch.id) {
            return updatedBatch;
          }

          return item;
        });
        // state.uncompletedBatches = state.uncompletedBatches.map((item) => {
        //   if (item.id === updatedBatch.id) {
        //     return updatedBatch;
        //   }

        //   return item;
        // });
      }
    },
    decrement: (
      state,
      { payload: { newQuantity, orderItemId } }: PayloadAction<Decrement>
    ) => {
      const currentBatch = state.groupedBatches.find(
        (item) => item.id === state.currentCard
      ) as IBatchItem;

      // const currentBatch = state.uncompletedBatches.find(
      //   (item) => item.id === state.currentCard
      // ) as IBatchItem;
      if (newQuantity >= 0) {
        const updatedBatch: IBatchItem = {
          ...currentBatch,
          orderItems: currentBatch.orderItems.map((item) => {
            if (item.id === orderItemId) {
              return {
                ...item,
                quantity: newQuantity,
              };
            }

            return item;
          }),
        };

        state.groupedBatches = state.groupedBatches.map((item) => {
          if (item.id === updatedBatch.id) {
            return updatedBatch;
          }

          return item;
        });
        // state.uncompletedBatches = state.uncompletedBatches.map((item) => {
        //   if (item.id === updatedBatch.id) {
        //     return updatedBatch;
        //   }

        //   return item;
        // });
      }
    },
    setUnCompletedBatches: (state, { payload }) => {
      state.groupedBatches = payload;
    },
    setCompletedBatches: (state, { payload }) => {
      state.completedBatches = payload;
    },
    setCurrentCard: (state, { payload }) => {
      state.currentCard = payload;
    },
    checkOrderItem: (
      state,
      { payload }: PayloadAction<{ orderItemId: string; isChecked: boolean }>
    ) => {
      state.groupedBatches = state.groupedBatches.map((item) => {
        if (item.id === state.currentCard) {
          return {
            ...item,
            orderItems: item.orderItems.map((item) => {
              if (item.id === payload.orderItemId) {
                return {
                  ...item,
                  isChecked: payload.isChecked,
                };
              }

              return item;
            }),
          };
        }

        return item;
      });
    },
  },
});

export const {
  decrement,
  increment,
  setCopyBatches,
  setPreviousBatches,
  clearCopyBatches,
  setUnCompletedBatches,
  setCompletedBatches,
  setCurrentCard,
  checkOrderItem,
  setIsCompleteInProgress,
} = productsSlice.actions;
export default productsSlice.reducer;
