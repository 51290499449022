import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

interface SpanProps {
  white: boolean;
}
interface NavigationLinkProps {
  activetab: string;
}

export const SideBarContainer = styled.div`
  height: 100vh;
  width: 140px;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  box-sizing: border-box;
  float: left;
  background-color: #184857;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 25px 0 0;
  overflow-x: hidden;
  font-family: "NiveauGrotesk-Bold";
  letter-spacing: 0.7px;
`;

export const NavigationLink = styled(NavLink)<NavigationLinkProps>`
  width: 100%;
  padding-left: 15px;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  border-left: 5px transparent solid;
  border-color: ${(props) =>
    props.activetab === "true" ? "orange" : "transparent"};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 50;
  word-spacing: 16;
  svg {
    margin-bottom: 11px;
  }
`;

export const StyledSpan = styled.span<SpanProps>`
  color: ${(props) => (props.white ? "white" : "#8ea3ac")};
`;
