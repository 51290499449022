import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { completeBatch, getBatches, updateBatch } from "../../api/batches";
import { IUpdateBatchPayload } from "../../fetures/CardProduct/model";
import { ICompleteBatchPayload } from "./products.types";

export const completeBatchThunk = createAsyncThunk(
  "products/nextBatch",
  async ({ id, abortSignal, ...data }: ICompleteBatchPayload) => {
    return new Promise<AxiosResponse>((resolve) => {
      setTimeout(async () => {
        completeBatch(id, abortSignal, data).then((response) =>
          resolve(response.data)
        );
      }, 4000);
    });
  }
);

export const getBatchesThunk = createAsyncThunk(
  "products/getBatches",
  async () => {
    const { data } = await getBatches();

    return data;
  }
);

export const updateBatchThunk = createAsyncThunk(
  "products/updateBatches",
  async (data: IUpdateBatchPayload) => {
    await updateBatch(data);
  }
);
