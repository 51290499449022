import { createSlice } from "@reduxjs/toolkit";
import { getStatusBatchesThunk } from "./status.thunks";
import { IStatusState } from "./status.types";

const initialState: IStatusState = {
  progress: [],
  completed: [],
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatusBatchesThunk.fulfilled, (_, { payload }) => {
      return payload;
    });
  },
});

export default statusSlice.reducer;
