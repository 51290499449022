import { FC } from "react";
import { Navigate } from "react-router-dom";
import { RoutesProps } from "./model";
import { useLogin } from "../../common/utils/hooks/useLogin";
import { RoutesPaths } from "../../common/RoutePaths";

const LoginRoute: FC<RoutesProps> = ({ children }): JSX.Element => {
  const isLogin = useLogin();

  return isLogin ? <Navigate to={RoutesPaths.PRODUCTIE} /> : <>{children}</>;
};

export default LoginRoute;
