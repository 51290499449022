import { createAsyncThunk } from "@reduxjs/toolkit";

import { IBatchItem } from "../products/products.types";
import { getPlanningBatches } from "../../api/planning";

export const getPlanningBatchesThunk = createAsyncThunk(
  "planning/getBatches",
  async (): Promise<IBatchItem[]> => {
    const { data } = await getPlanningBatches();

    return data;
  }
);
