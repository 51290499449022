import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStatusBatches } from "../../api/status";
import { IStatusState } from "./status.types";

export const getStatusBatchesThunk = createAsyncThunk<IStatusState>(
  "status/getBatches",
  async () => {
    const { data } = await getStatusBatches();

    return data;
  }
);
