import React from "react";

export interface StatusProps {
  isActive?: boolean;
}

const Status: React.FC<StatusProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.63"
      height="25.051"
      viewBox="0 0 28.63 25.051"
    >
      <path
        id="table-solid"
        d="M25.946,32H2.684A2.684,2.684,0,0,0,0,34.684V54.367a2.684,2.684,0,0,0,2.684,2.684H25.946a2.684,2.684,0,0,0,2.684-2.684V34.684A2.684,2.684,0,0,0,25.946,32ZM12.526,53.472H3.579V48.1h8.947Zm0-8.947H3.579V39.157h8.947Zm12.526,8.947H16.1V48.1h8.947Zm0-8.947H16.1V39.157h8.947Z"
        transform="translate(0 -32)"
        fill="#fff"
        opacity={isActive ? "1" : "0.5"}
      />
    </svg>
  );
};

export default Status;
