import React, { FC } from "react";

interface PlanningProps {
  isActive?: boolean;
}

const Planning: FC<PlanningProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.88"
      height="29.882"
      viewBox="0 0 29.88 29.882"
    >
      <path
        id="tools-solid"
        d="M29.2,23.092l-6.834-6.834a4.333,4.333,0,0,0-4.984-.811L11.157,9.226V5.6L3.688,0-.047,3.735l5.6,7.47H9.179L15.4,17.425a4.342,4.342,0,0,0,.811,4.984l6.834,6.834a2.17,2.17,0,0,0,3.075,0L29.2,26.167a2.18,2.18,0,0,0,0-3.075ZM19.31,13.13a6.14,6.14,0,0,1,4.371,1.809l1.132,1.132a8.26,8.26,0,0,0,2.556-1.722,8.392,8.392,0,0,0,2.212-7.977.7.7,0,0,0-1.173-.321l-4.342,4.342L20.1,9.734l-.659-3.962L23.786,1.43A.7.7,0,0,0,23.453.251a8.406,8.406,0,0,0-7.972,2.212,8.27,8.27,0,0,0-2.4,6.046L17.868,13.3a6.357,6.357,0,0,1,1.441-.169Zm-6.063,4.785L9.938,14.607l-8.894,8.9a3.734,3.734,0,0,0,5.281,5.281l7.213-7.213a6.267,6.267,0,0,1-.292-3.659ZM3.688,27.544a1.4,1.4,0,1,1,1.4-1.4A1.4,1.4,0,0,1,3.688,27.544Z"
        transform="translate(0.05)"
        opacity={isActive ? "1" : "0.5"}
        fill="#fff"
      />
    </svg>
  );
};

export default Planning;
