import React, { FC } from "react";

interface PlanningProps {
  isActive?: boolean;
}

const Planning: FC<PlanningProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.155"
      height="23.689"
      viewBox="0 0 29.155 23.689"
    >
      <path
        fill={"#fff"}
        opacity={isActive ? "1" : "0.5"}
        id="list-solid"
        d="M4.556,66.222H.911A.911.911,0,0,0,0,67.133v3.644a.911.911,0,0,0,.911.911H4.556a.911.911,0,0,0,.911-.911V67.133A.911.911,0,0,0,4.556,66.222ZM4.556,48H.911A.911.911,0,0,0,0,48.911v3.644a.911.911,0,0,0,.911.911H4.556a.911.911,0,0,0,.911-.911V48.911A.911.911,0,0,0,4.556,48Zm0,9.111H.911A.911.911,0,0,0,0,58.022v3.644a.911.911,0,0,0,.911.911H4.556a.911.911,0,0,0,.911-.911V58.022A.911.911,0,0,0,4.556,57.111ZM28.244,67.133H10.022a.911.911,0,0,0-.911.911v1.822a.911.911,0,0,0,.911.911H28.244a.911.911,0,0,0,.911-.911V68.044A.911.911,0,0,0,28.244,67.133Zm0-18.222H10.022a.911.911,0,0,0-.911.911v1.822a.911.911,0,0,0,.911.911H28.244a.911.911,0,0,0,.911-.911V49.822A.911.911,0,0,0,28.244,48.911Zm0,9.111H10.022a.911.911,0,0,0-.911.911v1.822a.911.911,0,0,0,.911.911H28.244a.911.911,0,0,0,.911-.911V58.933A.911.911,0,0,0,28.244,58.022Z"
        transform="translate(0 -48)"
      />
    </svg>
  );
};

export default Planning;
