import React from "react";

const Logo = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="66px"
    height="80"
    style={{
      marginBottom: "40px",
      marginLeft: "25px",
    }}
    viewBox="0 0 61.667 80"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M30.781,0,.095,39.611,0,39.732,30.394,79.779l.167.221L61.572,39.787l.095-.124Z"
        fill="#fff"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M115.982,153.93,103.848,169.8l-3.418-39.6Z"
        transform="translate(-72.475 -94.631)"
        fill="#4a889b"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M133.091,128.328,119.654,145.9,103.66,121.5Z"
        transform="translate(-74.806 -88.084)"
        fill="#55c7de"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M129.361,50.361,99.61,43.455l3.747-26.775Z"
        transform="translate(-71.883 -12.037)"
        fill="#f59700"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M68.04,20.89,64.574,45.625,53.81,39.406Z"
        transform="translate(-38.832 -15.075)"
        fill="#d06d0f"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M35.41,99.33l-19.58,6.154L25.119,93.39Z"
        transform="translate(-11.424 -67.65)"
        fill="#f59700"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M37.746,162.191,11.82,127.736l22.344-7.026Z"
        transform="translate(-8.53 -87.783)"
        fill="#154857"
      />
    </g>
  </svg>
);

export default Logo;
