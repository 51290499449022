const colors = {
  orange: "#F49711",
  whiteTableHeader: "#E5E5E5",
  whiteViewItem: "#F1F4F9",
  dark: "#20252B",
  orangeLight: "#FE724C",
  orangeLightHover: "#ff8d6e",
  orangeLightDisabled: "#ffa78f",
  grayAdminText: "#556069",
  grayLight: "#DEE1E7",
  grayInputBorder: "#E6E6E6",
  grayInputFocus: "#6D6F7A",
  grayListIcon: "#C2C3C7",
  grayTabTitle: "#999BA1",
  grayIconSVG: "#A1A9B3",
  grayToggle: "#B6B6B6",
  grayWrapper: "#EDF0F2",
  darkInputText: "#343643",
  grayInputText: "#434b4e",
  redErr: "#E53247",
  red: "#D22E21",
  grayOut: "#CCCCCC",
  blueLinkTable: "#2F80ED",
  grayStandart: "#A1A9B2",
  blueInput: "#4B879A",
  transparent: "transparent",
};
const orangeColors: any = Array.from({ length: 10 }, () => colors.orange);
export { colors, orangeColors };
