import { FC } from "react";

interface ArchiefProps {
  isActive?: boolean;
}

const Archief: FC<ArchiefProps> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.155"
      height="25.511"
      viewBox="0 0 29.155 25.511"
    >
      <path
        id="archive-solid"
        d="M1.822,55.689a1.82,1.82,0,0,0,1.822,1.822H25.511a1.82,1.82,0,0,0,1.822-1.822v-16.4H1.822Zm9.111-12.072a.685.685,0,0,1,.683-.683h5.922a.685.685,0,0,1,.683.683v.456a.685.685,0,0,1-.683.683H11.617a.685.685,0,0,1-.683-.683ZM27.333,32H1.822A1.82,1.82,0,0,0,0,33.822v2.733a.914.914,0,0,0,.911.911H28.244a.914.914,0,0,0,.911-.911V33.822A1.82,1.82,0,0,0,27.333,32Z"
        transform="translate(0 -32)"
        fill="#fff"
        opacity={isActive ? "1" : "0.5"}
      />
    </svg>
  );
};

export default Archief;
