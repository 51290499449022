import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { RoutesPaths } from "../../common/RoutePaths";
import ArchiefPage from "../../pages/ArchiefPage";
import LoginPage from "../../pages/LoginPage";
import OptiesPage from "../../pages/OptiesPage";
import PlanningPage from "../../pages/PlanningPage";
import ProductsPage from "../../pages/ProductsPage";
import StatusPage from "../../pages/StatusPage";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";
import { Main } from "./styled";

const RoutesNavigation: FC = () => {
  return (
    <Main>
      <Routes>
        <Route
          path={RoutesPaths.LOGIN}
          element={
            <LoginRoute>
              <LoginPage />
            </LoginRoute>
          }
        ></Route>

        <Route
          path={RoutesPaths.PRODUCTIE}
          element={
            <PrivateRoute>
              <ProductsPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={RoutesPaths.STATUS}
          element={
            <PrivateRoute>
              <StatusPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={RoutesPaths.PLANNING}
          element={
            <PrivateRoute>
              <PlanningPage />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={RoutesPaths.ARCHIEF}
          element={
            <PrivateRoute>
              <ArchiefPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={RoutesPaths.OPTIES}
          element={
            <PrivateRoute>
              <OptiesPage />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </Main>
  );
};
export default RoutesNavigation;
