import RoutesNavigation from "../containers/RoutesNavigation";
import { MantineProvider } from "@mantine/core";
import { orangeColors } from "../common/utils/consts/colors";

import SideBar from "../common/components/SideBar";
import { useLogin } from "../common/utils/hooks/useLogin";

import "./App.css";

function App() {
  const isLogin = useLogin();

  return (
    <MantineProvider
      theme={{
        colorScheme: "light",
        colors: {
          orange: orangeColors,
        },
      }}
    >
      {isLogin && <SideBar />}
      <RoutesNavigation />
    </MantineProvider>
  );
}

export default App;
