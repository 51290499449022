import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import archiveSlice from "./archive/archive.slice";
import optionsSlice from "./options/options.slice";
import planningSlice from "./planning/planning.slice";
import productsReducer from "./products/products.slice";
import statusSlice from "./status/status.slice";

export const store = configureStore({
  reducer: {
    productsReducer,
    options: optionsSlice,
    archive: archiveSlice,
    planning: planningSlice,
    status: statusSlice,
  },
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
