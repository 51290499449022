import { createAsyncThunk } from "@reduxjs/toolkit";

import { runAlgo } from "../../api/order-items";
import { getSettings, updateBatchesSettings } from "../../api/settings";
import {
  updateTotalOrderItems,
  getTotalOrderItems,
} from "../../api/woocommerce-products";
import {
  IOrderItem,
  IRunAlgoPayload,
  ISettings,
  IUpdateBatchesSettings,
  IUpdateOrderItem,
} from "./options.types";

export const getOrderItemsThunk = createAsyncThunk(
  "options/getOrderItems",
  async (): Promise<IOrderItem[]> => {
    const { data } = await getTotalOrderItems();

    return data;
  }
);

export const updateOrderItemsThunk = createAsyncThunk(
  "options/updateOrderItems",
  async (items: IUpdateOrderItem[]) => {
    await updateTotalOrderItems(items);

    return items;
  }
);

export const runAlgoThunk = createAsyncThunk(
  "options/runAlgo",
  async (params: IRunAlgoPayload = {}): Promise<number> => {
    const { status } = await runAlgo(params);

    return status;
  }
);

export const getSettingsThunk = createAsyncThunk(
  "options/getSettings",
  async (): Promise<ISettings> => {
    const { data } = await getSettings();

    return data;
  }
);

export const updateBatchesSettingsThunk = createAsyncThunk(
  "options/updateBatchesSettings",
  async (settings: IUpdateBatchesSettings) => {
    await updateBatchesSettings(settings);

    return settings;
  }
);
