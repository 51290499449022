import { createSlice } from "@reduxjs/toolkit";

import { PlanningStateType } from "./planning.types";
import { getPlanningBatchesThunk } from "./planning.thunks";

const initialState: PlanningStateType = {
  batches: [],
};

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlanningBatchesThunk.fulfilled, (state, { payload }) => {
      state.batches = payload;
    });
  },
});

export default planningSlice.reducer;
