import { createAsyncThunk } from "@reduxjs/toolkit";

import { IOrderItem } from "../options/options.types";
import { getArchiveOrderItems } from "../../api/archive";
import { IGetArchiveOrderItemsPayload } from "./archive.types";

export const getArchiveOrderItemsThunk = createAsyncThunk(
  "archive/getOrderItems",
  async (payload: IGetArchiveOrderItemsPayload): Promise<IOrderItem[]> => {
    const { data } = await getArchiveOrderItems(payload);

    return data;
  }
);
