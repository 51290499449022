import Loader from "../common/components/Loader";
import React, { FC, Suspense } from "react";
const LoginContainer = React.lazy(() => import("../containers/LoginContainer"));

const LoginPage: FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <LoginContainer />
      </Suspense>
    </>
  );
};

export default LoginPage;
