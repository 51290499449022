import Loader from "../common/components/Loader";
import React, { FC, Suspense } from "react";
const ProductsContainer = React.lazy(
  () => import("../containers/ProductsContainer")
);

const ProductsPage: FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <ProductsContainer />
      </Suspense>
    </>
  );
};

export default ProductsPage;
