import { createSlice } from "@reduxjs/toolkit";

import { ArchiveStateType } from "./archive.types";
import { getArchiveOrderItemsThunk } from "./archive.thunks";

const initialState: ArchiveStateType = {
  orderItems: [],
};

const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getArchiveOrderItemsThunk.fulfilled,
      (state, { payload }) => {
        state.orderItems = payload;
      }
    );
  },
});

export default archiveSlice.reducer;
